import React, { useState } from "react";
// import { useHistory } from "react-router-dom"; 
import { Link, useHistory } from "react-router-dom";

import axios from "axios";
import Swal from "sweetalert2";

const BankDetails = () => {
  const history = useHistory();

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const [formData, setFormData] = useState({
    accountNumber: "",
    ifscCode: "",
    accountHolderName: "",
    phoneNumber: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.accountNumber || !formData.ifscCode || !formData.accountHolderName || !formData.phoneNumber) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "All fields are required!",
      });
      return;
    }

    try {
      setLoading(true);
      let access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      const response = await axios.post(
        `${baseUrl}bankaccount_verify`,
        {
          accountNumber: formData.accountNumber,
          ifscCode: formData.ifscCode,
          accountHolderName: formData.accountHolderName,
          phone: formData.phoneNumber,
        },
        { headers }
      );
      // console.log("ghhgssssss", response.data.data);
      // console.log("response.data.account_status", response.data.data?.[0].bankaccount_status);
      if (response.data && response.data.data?.some(acc => acc?.bankaccount_status === "VALID")) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response.data.message || "Bank account verified successfully!",
        }).then(() => {

          history.push("/profile");
          window.location.reload(true);


        })
      } else if (response.data && response.data.data.account_status === "INVALID") {
        // console.log(response.data.message);
        Swal.fire({
          icon: "error",
          title: "Verification Failed",
          text: response.data.message || "Failed to verify account. Please try again.",
        })
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Verification Failed",
        text: err.response?.data?.message || "Failed to verify account. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Bank Account Verification</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.field}>
          <label style={styles.label}>Account Holder Name:</label>
          <input
            type="text"
            name="accountHolderName"
            value={formData.accountHolderName}
            onChange={handleChange}
            style={styles.input}
            placeholder="Enter your name as per bank records"
          />
        </div>
        <div style={styles.field}>
          <label style={styles.label}>Account Number:</label>
          <input
            type="text"
            name="accountNumber"
            value={formData.accountNumber}
            onChange={handleChange}
            style={styles.input}
            placeholder="Enter your account number"
          />
        </div>
        <div style={styles.field}>
          <label style={styles.label}>IFSC Code:</label>
          <input
            type="text"
            name="ifscCode"
            value={formData.ifscCode}
            onChange={handleChange}
            style={styles.input}
            placeholder="Enter your IFSC code"
          />
        </div>
        <div style={styles.field}>
          <label style={styles.label}>Mobile Number:</label>
          <input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            style={styles.input}
            placeholder="Enter your mobile number linked to the bank"
          />
        </div>
        <button type="submit" style={styles.button} disabled={loading}>
          {loading ? "Verifying..." : "Verify Account"}
        </button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "400px",
    margin: "40px",
    padding: "100px 0px",
    background: "#f9f9f9",
    borderRadius: "10px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  header: {
    textAlign: "center",
    marginBottom: "20px",
    color: "#333",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  field: {
    marginBottom: "15px",
  },
  label: {
    fontWeight: "bold",
    marginBottom: "5px",
    display: "block",
    color: "#555",
  },
  input: {
    padding: "10px",
    fontSize: "14px",
    width: "100%",
    border: "1px solid #ccc",
    borderRadius: "5px",
    boxSizing: "border-box",
  },
  button: {
    padding: "10px",
    background: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "16px",
  },
};

export default BankDetails;
